import { useAuthStore } from '~~/stores/auth'

export default defineNuxtRouteMiddleware(async (to) => {
  try {
    const { isAuthenticated, isVerified } = storeToRefs(useAuthStore())
    const { init, hasOneOfRoles } = useAuthStore()

    // If authenticated but not verified, redirect to verification page.
    if (
      isAuthenticated.value &&
      !isVerified.value &&
      to.path !== '/auth/sign-verify'
    ) {
      return navigateTo('/auth/sign-verify')
    }

    // Try to init auth session by refresh token cookie (if it exists).
    if (!isAuthenticated.value) {
      await init()
    }

    // If authentication is not passed by the step above, redirect to sign in.
    if (!isAuthenticated.value) {
      const queryRedirect =
        to.fullPath !== '/' && !to.fullPath.startsWith('/auth/sign-in')
          ? `?redirect=${to.fullPath}`
          : ''

      return navigateTo(`/auth/sign-in${queryRedirect}`)
    }

    // If authentication is successful, check the authorization access by
    // certain route (page) rules, if any.
    if (to.meta.roles?.length && !hasOneOfRoles(to.meta.roles)) {
      return abortNavigation(
        createError({
          statusCode: 404,
          statusMessage: 'Not found',
          message: 'Resource not found',
        }),
      )
    }
  } catch (e) {
    useLogger().debug(e)

    return navigateTo(`/auth/sign-in`)
  }
})
